//
// text.scss
//

// Weight

.font-weight-semibold {
  font-weight: $font-weight-semibold !important;
}

;@import "sass-embedded-legacy-load-done:126";