//
// social.scss
//

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;

  &:hover {
    color: $gray-600;
    border-color: $gray-600;
  }
}

;@import "sass-embedded-legacy-load-done:128";