.reservation-guests {
  &__group {
    & + & {
      margin-top: 20px;
    }

    &__ghost-guest {
      background: $grey;
      color: $gray-400;
      border: 1px dashed $gray-400;
      transform: scaleY(0);
      transform-origin: top;
      transition:
        transform 200ms linear,
        max-height 200ms linear;
      max-height: 0;
      display: flex;
      align-items: center;

      &--hovered {
        transform: scaleY(1);
        max-height: 45px;
        transition:
          transform 200ms linear,
          max-height 200ms linear;
      }
    }
  }

  &__table {
    &__column {
      vertical-align: middle !important;
      font-size: 12px;

      &__action {
        font-size: 14px;
        cursor: pointer;
      }
    }

    &__column-empty {
      border-top: none;
      border-bottom: none;
      background-color: $white !important;
    }

    .reservation-guests__table__column-cars {
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
      vertical-align: middle;
      background-color: $white;

      &__first {
        border-left: 1px solid $light-gray;
      }

      &__last {
        border-right: 1px solid $light-gray;
      }
    }

    &__gastro-voucher {
      &__first-column {
        width: 190px;
      }

      &__second-column {
        width: 230px;
      }
    }
  }

  &__voucher-dialog {
    .close {
      color: $white;
      opacity: 1;
    }
  }
}

;@import "sass-embedded-legacy-load-done:176";