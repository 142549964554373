@import 'variables';

.reception {
  &__booking-check-out {
    width: 800px;
    max-width: 800px;

    .border {
      border-color: #cfd0d0 !important;
    }

    &__bill-step {
      &__print {
        margin-bottom: 3px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        border-radius: 3px;
        font-size: 14px;
        padding: 0 15px;
        cursor: pointer;
        color: #5a6066;
        background-color: #ededee;
        border: 1px solid #192551;
        font-weight: bold;

        &__icon {
          margin-top: 3px;
          margin-left: auto;
          font-size: 16px;
          color: #9fa8ae;
        }
      }

      &__table {
        font-size: 11px;
        width: 100%;
        border: 1px solid #c6c8ca;

        &__column {
          padding: 5px 0;
          border-bottom: 1px solid #c6c8ca;

          &:first-child {
            padding-left: 15px;
          }

          &--is-sub {
            padding-left: 25px !important;
          }

          &--is-costs {
            color: $danger-dark;
            font-weight: 500;
          }

          &--is-payin {
            color: $success;
            font-weight: bold;
          }
        }

        &__row {
          &.bg-platinum {
            background-color: #f5f5f5 !important;
          }

          &--is-last {
            .reception__booking-check-out__bill-step__table__column {
              border-top: 1px solid #c6c8ca;
              border-bottom: 1px solid #c6c8ca;
            }
          }

          &--is-sub {
            .reception__booking-check-out__bill-step__table__column {
              border-bottom: 1px solid #eeefef;
            }
          }
        }

        &__row {
          &--is-main {
            .reception__booking-check-out__bill-step__table__column {
              border-bottom-color: #c6c8ca;
            }
          }
        }
      }
    }

    &__keys-step {
      &__key-icon {
        font-size: 130px;
        text-align: right;
      }
    }

    &__thanks-step {
      &__info {
        &__block {
          justify-content: space-between;
        }

        &__text {
          width: 680px;
          color: #1e5b6d;
          font-size: 12px;

          &__item {
            margin-bottom: 10px;
          }
        }

        &__icon {
          text-align: center;
          width: 66px;
          height: 66px;
          min-height: 66px;
          min-width: 66px;
          background-color: #d7eff6;
          border: 1px solid #c8e9f2;
          color: #1e5b6d;
          font-size: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 33px;
          box-shadow: 0 0 10px #f1f6f8;
          margin-right: 20px;
        }
      }
    }

    &__receipt-box {
      label {
        font-weight: 500;
      }

      .form-group {
        margin-bottom: 0;
      }

      .form__input--select {
        background-color: white;
      }
    }
  }

  &__booking-check-out-preview {
    &__access-keys-return {
      width: 700px;
    }

    &__content {
      width: 1200px;

      .receipt-send-button {
        margin-top: 12px;
        width: 199px;
        margin-left: 0;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:175";