@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

@import '@assets/scss/config/saas/_variables-dark.scss';

.rcp__events-table {
  --table-background-color: white;
  --table-border-color: #{$indigo};

  .thead-light {
    th {
      border-bottom: none;
    }
  }

  td,
  th {
    padding-bottom: 0;
    padding-top: 0;
    height: 45px;
  }

  &__edit-row {
    td {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    input[type='text'] {
      text-align: center;
    }

    input[type='text'],
    select {
      height: 44px !important;
      line-height: 44px !important;
    }
  }

  &__tr-primary-top {
    border-top: 2px solid var(--table-border-color);
  }

  &__tr-primary-top,
  &__tr-primary-middle,
  &__tr-primary-bottom {
    border-left: 2px solid var(--table-border-color);
    border-right: 2px solid var(--table-border-color);
    background-color: var(--table-background-color);

    select,
    input[type='text'] {
      border-radius: 0;
    }

    &.has-errors {
      --table-border-color: #dd3535;
      --table-background-color: rgba(221, 53, 53, 0.18);

      td {
        border-top: 1px solid #e4d5d4 !important;
      }

      select,
      input[type='text'] {
        border: 1px solid #dd3535;
        border-radius: 0;
      }
    }
  }

  &__tr-primary-bottom {
    border-bottom: 2px solid var(--table-border-color);
  }

  &__has-errors {
    &__column-day {
      border-left: 2px solid #dd3535;
    }

    td {
      font-weight: bold;
    }
  }
}

;@import "sass-embedded-legacy-load-done:168";