.reservation-create {
  &__step-insurance {
    &__box {
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      background-color: $gray-50;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 6px 10px 17px;
    }

    &__text {
      width: 70%;
    }

    &__text-header {
      font-weight: $font-weight-semibold;
    }

    &__text-subheader,
    &__text-header-cost {
      font-size: 0.8em;
    }

    &__paragraph {
      font-size: 0.95em;
      margin-top: 7px;
    }

    &__delete {
      display: flex;
      align-items: center;
    }

    &__delete-button {
      font-weight: $font-weight-semibold;
      font-size: 0.95em;
    }
  }

  &__step-summary {
    &__text-light {
      color: $gray-500;
    }

    &__with-checkmark {
      padding-left: 36px;
      position: relative;
    }

    &__checkmark {
      position: absolute;
      left: 13px;
      top: -4px;
    }

    &__with-volume {
      padding-left: 24px;
      position: relative;
    }

    &__volume {
      position: absolute;
      left: 4px;
      top: 0;
    }

    &__text-normal {
      color: $body-font-color;
    }

    &__list {
      padding-left: 14px;
    }

    &__list-el {
      padding-left: 8px;
      margin-bottom: 10px;
    }

    &__vip {
      border: 1px solid $gold-light;
      font-size: 7px;
      font-weight: 600;
      color: $gold-light;
      padding: 0px 3px;
      margin-left: 4px;
      vertical-align: middle;
    }

    &__icon-label {
      padding-left: 18px;
    }
  }

  &__summary-box {
    width: 280px;
    position: fixed;
    background: linear-gradient(270deg, #c2c2c2 0%, #c0c0c0 100%);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &__wrapper {
      position: absolute;
      top: 55px;
      z-index: -1;

      &.is-visible {
        animation: fadeIn linear 100ms forwards;
        animation-fill-mode: forwards;
      }
    }

    &__separator {
      border-color: rgba(118, 130, 140, 0.5);
      width: 100%;
    }

    &__prices {
      background: linear-gradient(270deg, #848484 0%, #6f6f6f 100%);
      border-bottom-right-radius: 4px;

      &__total {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    right: 280px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

;@import "sass-embedded-legacy-load-done:179";