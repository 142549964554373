//
// dropzone.scss
//

.dropzone {
  border: 2px dashed $input-border-color;
  background: $input-bg;
  border-radius: 6px;

  &__wrapper {
    cursor: pointer;
    padding: 20px;
    min-height: 100px;
  }

  .dz-message {
    text-align: center;
    margin: 2rem 0;
  }

  &.dz-started {
    .dz-message {
      display: none;
    }
  }
}

;@import "sass-embedded-legacy-load-done:137";