.table {
  &__wrapper {
    &--scrollable {
      overflow-y: hidden;
      overflow-x: scroll;
    }
    &--scrollable-y {
      overflow-y: auto;
      table {
        border-collapse: collapse;
        margin-bottom: 0;
        th {
          position: sticky;
          top: 0;
          border-top: none;
        }
      }
    }

    @include light-scrollbar(5px, 5px);
  }

  &__header {
    background-color: #f1f3fa;
    user-select: none;
    vertical-align: top !important;
  }

  &__sort-indicator {
    color: rgba($text-muted, 0.5);

    &.is-active {
      color: $text-muted;
    }

    &__desc {
      position: absolute;
      right: -23px;
      top: -15px;
    }

    &__asc {
      position: absolute;
      right: -23px;
      top: -7px;
    }
  }

  &__link-row {
    color: inherit;
    position: relative;

    &:hover {
      color: inherit;
    }

    &:after {
      content: '';
      width: 100vw;
      margin-left: -0.95rem;
      margin-top: -0.95rem;
      opacity: 0.05;
      position: absolute;
      height: calc(100% + calc(0.95rem * 2));
      top: 0;
      left: 0;
    }
  }
}

.table-pagination {
  &__page-slider {
    position: absolute;
    right: 50px;
    max-width: 125px;
    transition:
      right 100ms linear,
      left 100ms linear;

    &__wrapper {
      max-width: 225px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      overflow: hidden;

      &--disabled {
        display: none;
      }
    }

    &__button {
      height: 25px;
      min-width: 25px;
      width: 25px;
      color: $dark-gray;
      font-weight: 500;
      text-align: center;
      white-space: nowrap;
      transition: all 0.2s ease;
      cursor: pointer;
      vertical-align: middle;
      line-height: 27px;

      &:hover:not(.table-pagination__page-slider__button--active) {
        font-size: 15px;
        transform: translateY(-1px);
      }

      &--active {
        background-color: $dark-blue;
        border-radius: 50%;
        color: $white;
        font-weight: bold;
      }

      &__move {
        font-size: 16px;
      }

      &__wrapper {
        position: relative;
      }
    }
  }

  &__slide-button {
    font-size: 18px;
    background-color: $white;
    z-index: 1;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 100ms linear;

    &--disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.3;
    }
  }
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: white;
}

;@import "sass-embedded-legacy-load-done:157";