.hr {
  &__annex-list-indent {
    position: absolute;
    left: -30px;
    bottom: 45%;
    height: calc(100% + 10px);
    width: 1px;
    background-color: #aab8c5;

    &.is-first {
      height: 50%;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 25px;
      height: 1px;
      background-color: $gray-500;
    }
  }
}

;@import "sass-embedded-legacy-load-done:191";