@font-face {
  font-family: 'icomoon';
  src: url('../fonts/improvements.eot');
  src:
    url('../fonts/improvements.eot#iefix') format('embedded-opentype'),
    url('../fonts/improvements.ttf') format('truetype'),
    url('../fonts/improvements.woff') format('woff'),
    url('../fonts/improvements.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wentylator:before {
  content: '\e912';
  color: #b99153;
}
.icon-zagiel:before {
  content: '\e91f';
  color: #b99153;
}
.icon-meldowanie:before {
  content: '\e920';
  color: #b99153;
}
.icon-parking-duze:before {
  content: '\e91e';
  color: #b99153;
}
.icon-parking-gwarantowany:before {
  content: '\e91d';
  color: #b99153;
}
.icon-zegarek:before {
  content: '\e91b';
  color: #b99153;
}
.icon-konsola:before {
  content: '\e911';
  color: #b99153;
}
.icon-helicopter:before {
  content: '\e91a';
  color: #b99153;
}
.icon-wyzywienie:before {
  content: '\e919';
  color: #39afd1;
}
.icon-lokal:before {
  content: '\e916';
  color: #b99153;
}
.icon-vip:before {
  content: '\e917';
  color: #b99153;
}
.icon-ekspress:before {
  content: '\e90e';
  color: #b99153;
}
.icon-kapsulki:before {
  content: '\e90f';
  color: #b99153;
}
.icon-klima:before {
  content: '\e910';
  color: #b99153;
}
.icon-mikrofalowka:before {
  content: '\e913';
  color: #b99153;
}
.icon-wym20:before {
  content: '\e914';
  color: #b99153;
}
.icon-wym12:before {
  content: '\e915';
  color: #b99153;
}
.icon-kluczyk:before {
  content: '\e900';
  color: #b99153;
}
.icon-lozeczko:before {
  content: '\e901';
  color: #b99153;
}
.icon-opaska1:before {
  content: '\e902';
  color: #b99153;
}
.icon-pralnia:before {
  content: '\e903';
  color: #b99153;
}
.icon-grill:before {
  content: '\e904';
  color: #b99153;
}
.icon-krzeselko:before {
  content: '\e905';
  color: #b99153;
}
.icon-parking:before {
  content: '\e906';
  color: #b99153;
}
.icon-reczniki:before {
  content: '\e907';
  color: #b99153;
}
.icon-rowery:before {
  content: '\e90a';
  color: #b99153;
}
.icon-sprzatanie:before {
  content: '\e90b';
  color: #b99153;
}
.icon-wanienka:before {
  content: '\e90c';
  color: #b99153;
}
.icon-zwierzeta:before {
  content: '\e90d';
  color: #b99153;
}
.icon-tablet:before {
  content: '\e91c';
  color: #0acf97;
}
.icon-vipBg:before {
  content: '\e918';
  color: #c09c64;
}
.icon-klucz:before {
  content: '\e908';
  color: #0acf97;
}
.icon-opaska:before {
  content: '\e909';
  color: #0acf97;
}

;@import "sass-embedded-legacy-load-done:1";