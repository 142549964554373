@mixin unavailable-background-style {
  background-image: linear-gradient(
    135deg,
    #e1e1e1 25%,
    #d5d5d5 25%,
    #d5d5d5 50%,
    #e1e1e1 50%,
    #e1e1e1 75%,
    #d5d5d5 75%,
    #d5d5d5 100%
  );
}

$statuses: (
  close: $reservation-paid-color,
  confirmed: $reservation-confirmed-color,
  confirmed-before-ap-fee: $reservation-confirmed-before-ap-fee-color,
  unfinished: $reservation-unfinished-color,
  initial: $reservation-initial-color,
  initial-before-ap-fee: $reservation-initial-before-ap-fee-color,
  checkedin: $reservation-checked-color,
  edit: #960000,
  employee: $reservation-employee-color,
);

$statuses-darker: (
  close: $reservation-paid-color-darker,
  confirmed: $reservation-confirmed-color-darker,
  confirmed-before-ap-fee: $reservation-confirmed-before-ap-fee-color-darker,
  unfinished: $reservation-unfinished-color-darker,
  initial: $reservation-initial-color-darker,
  initial-before-ap-fee: $reservation-initial-before-ap-fee-color-darker,
  checkedin: $reservation-checked-color-darker,
  edit: #960000,
);

.calendar-content__status-booking {
  &.is-fake,
  &.is-block,
  &.is-system {
    color: #d5d5d5;
  }

  @each $name, $color in $statuses {
    &.is-#{$name} {
      color: $color;
    }
  }
}

.calendar-content__item {
  &.is-fake,
  &.is-block,
  &.is-system {
    @include unavailable-background-style;
    background-size: 25px 25px;
    border-color: #e1e1e1;

    &.is-extended {
      background-image: none;
      border-color: #d5d5d5;
      background-color: #d5d5d5;
    }
  }

  @each $name, $color in $statuses {
    &.is-#{$name} {
      border-color: $color;
      background-color: $color;
    }
  }

  @each $name, $color in $statuses-darker {
    &.is-extended.is-#{$name} {
      border-color: $color;
      background-color: $color;
    }
  }
}

.calendar-tooltip__status {
  &.is-fake,
  &.is-block,
  &.is-system {
    @include unavailable-background-style;
    background-size: 25px 25px;

    &.is-extended:after {
      background-image: none;
      background-color: #d5d5d5;
    }
  }

  @each $name, $color in $statuses {
    &.is-#{$name} {
      background-color: $color;
    }
  }

  @each $name, $color in $statuses-darker {
    &.is-extended.is-#{$name}:after {
      background-color: $color;
    }
  }
}

$calendarButtons: (
  add: #2c8f41,
  unfinished: $reservation-unfinished-color,
  paid: $reservation-paid-color,
  confirmed: $reservation-confirmed-color,
  confirmed-before-ap-fee: $reservation-confirmed-before-ap-fee-color,
  initial: $reservation-initial-color,
  initial-before-ap-fee: $reservation-initial-before-ap-fee-color,
  checked: $reservation-checked-color,
  without-animals: $reservation-animals-color,
  extended: $reservation-extended-color,
  employee: $reservation-employee-color,
  unavailable: none,
  local-without-animals: $reservation-animals-color,
  special-local: #8c0772,
  local-with-garden: #eff3f7,
  local-without-garden: #adb5bd,
);

@each $name, $color in $calendarButtons {
  .calendar__button {
    &.is-#{$name} {
      @if $name == 'unavailable' {
        @include unavailable-background-style;
        background-size: 25px 25px;
        color: #3f3f3f;
      } @else {
        background-color: $color;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:170";