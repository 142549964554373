.error-boundary {
  display: flex;
  flex-direction: column;

  &__eyes-wrapper {
    display: block;
    position: relative;
    width: 200px;
    margin: 10% auto 0;
    animation: shvr 0.2s infinite;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background: #fff; //one eye
      position: absolute;
      top: 30px;
      left: 25px;
      border-radius: 50%;
      box-shadow: 125px 0 0 #fff; //second eye
      animation: eye 2.5s infinite;
    }
  }

  &__eye {
    position: relative;
    display: inline-block;
    background: $navy-light; // eyesocket
    width: 75px;
    height: 80px;
    border-radius: 50% 50% 50% 50%/45px 45px 45% 45%;
    transform: rotate(45deg);

    &::after {
      content: '';
      position: absolute;
      border-bottom: 2px solid $navy-light; //eye socket shadow
      width: 70px;
      height: 50px;
      left: 0px;
      bottom: -10px;
      border-radius: 50%;
    }

    &::before {
      bottom: auto;
      top: -100px;
      transform: rotate(45deg);
      left: 0;
    }

    &:last-child {
      float: right;
      transform: rotate(-45deg);

      &::after {
        left: 5px;
      }
    }
  }

  &__text {
    text-align: center;
    margin-top: 50px;
    font-size: 30px;
  }

  &__subtext {
    white-space: pre-line;
    margin: 20px auto;
  }
}

@keyframes shvr {
  0% {
    transform: translate(1px, 1em);
  }
  50% {
    transform: translate(0, 1em);
  }
  100% {
    transform: translate(-1px, 1em);
  }
}

@keyframes eye {
  0%,
  30%,
  55%,
  90%,
  100% {
    transform: translate(0, 0);
  }
  10%,
  25% {
    transform: translate(0, 20px);
  }
  65% {
    transform: translate(-20px, 0);
  }
  80% {
    transform: translate(20px, 0);
  }
}

;@import "sass-embedded-legacy-load-done:184";