.date-picker {
  background-color: #fff;
  position: relative;
  min-width: 180px;

  &.is-with-month-name {
    min-width: unset;
    display: inline;
  }

  &.without-days {
    // overwrite
    .rdrMonths {
      display: none;
    }

    .rdrMonthAndYearWrapper {
      padding: 0;
    }

    .rorCalendarWrapper {
      display: flex;
    }
  }

  &.without-min-width {
    min-width: initial;
  }

  &__placeholder {
    color: #dadada;
  }

  &__input {
    width: calc(100% - 30px);
    outline: none;
    border: none;
    color: $body-font-color;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }

  &__clear {
    cursor: pointer;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 0;
    right: 7px;
    bottom: 0;
    margin: auto;

    &:after {
      background-color: $navy-light;
      border-radius: 50%;
      color: #fff;
      content: '\00d7';
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 1;
      padding: 2px;
      position: absolute;
      text-align: center;
    }
  }

  &__date {
    width: 100%;
    height: calc(2.1rem + 2px);
    padding: 0.45rem 0.9rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: $body-font-color;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;

    &.is-in-promocode-prefixes {
      background-color: #f3f3f3;
    }
  }

  &__wrapper {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 100%;
    box-shadow: 2px 5px 10px #c1c1c1;
  }

  &.is-disabled {
    background-color: black;
  }
}

;@import "sass-embedded-legacy-load-done:151";