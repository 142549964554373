.local-selection-modal {
  max-width: 980px;

  .modal-content {
    border-top-right-radius: 0;
  }

  &__map {
    padding-block: 5px;
    padding-left: 5px;
    width: 100%;
    height: calc(100% + 55px);

    &__wrapper {
      transition: height 100ms linear;
    }
  }

  &__zoom-buttons {
    &__wrapper {
      position: absolute;
      bottom: 15px;
      right: 10px;
      display: flex;
      flex-direction: column;
      border-radius: 4px;

      .btn {
        padding: 3px 7px;
      }
    }

    &__spacer {
      width: 70%;
      margin: 0 auto;
    }
  }

  &__apartments-list {
    flex: 1;
    display: block;

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background-color: #e0e0e0;
      border-radius: 20%;
    }

    ::-webkit-scrollbar-thumb {
      background: #a5a5a5;
      border-radius: 20%;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(#a5a5a5, 0.7);
    }

    &__wrapper {
      overflow-y: auto;
    }
  }

  &__close {
    position: absolute;
    right: -41px;
    top: 0;
    z-index: 1;
    background: #2323238c;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 0 4px 4px 0;
  }
}

;@import "sass-embedded-legacy-load-done:161";