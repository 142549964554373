.package-list {
  &__summary-cell {
    border-top: 3px solid #576067 !important;
    padding: 0 !important;
  }

  &__summary-block {
    background-color: #576067;
    height: 4rem;
    padding-left: 0.95rem;
    padding-top: 1.5rem;
  }
}

;@import "sass-embedded-legacy-load-done:186";