.upsell {
  &__view {
    font-size: 14px;
    min-height: 100vh;
  }

  &__header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 -4px 8px 0 rgba(66, 68, 90, 1);
    border-bottom: 1px solid #cbcbcb;
    height: 55px;
    position: relative;

    &__title {
      top: 50%;
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__back-icon {
      min-width: 50px;
    }

    &__menu {
      &__wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        border-left: 1px solid #cbcbcb;

        &:after {
          position: fixed;
          content: '';
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.5);
          transition: opacity 200ms linear;
          opacity: 0;
          left: 0;
          top: 0;
          pointer-events: none;
          z-index: 1;
        }

        &.is-visible {
          &:after {
            pointer-events: all;
            opacity: 1;
          }
        }
      }

      &__content {
        background-color: white;
        display: flex;
        flex-direction: column;
        height: 100vh;
        position: fixed;
        right: -300px;
        width: 300px;
        z-index: 4;
        top: 0;
        padding: 20px 10px;
        transition: right 200ms ease-in-out;

        &.is-visible {
          right: 0;
        }
      }
    }
  }

  &__tabs {
    width: 100%;

    .nav-link {
      white-space: nowrap;
      margin-bottom: 0;
      background-color: #fafbfe;
      border: none;
      border-bottom: 2px solid #fafbfe !important;
      border-radius: 0;
      border-right: 2px solid white;
      position: relative;

      &.active {
        color: $secondary;
        border-bottom-color: $secondary !important;
        border-right: none;

        &:after {
          content: '';
          width: 100%;
          height: 2px;
          bottom: -2px;
          left: 0;
          position: absolute;
          background: $secondary;
        }
      }
    }
  }

  &__survey {
    &__item {
      box-shadow: none;
      border: none;
      color: white;
      font-weight: 500;
      background-color: #adb5bd;
      border-radius: 22px;
      padding: 4px 15px 4px 28px;
      margin-right: 10px;
      display: flex;
      font-size: 13px;
      position: relative;
      transition: background-color 200ms linear;

      &__counter {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        white-space: nowrap;
      }

      &__circle {
        position: absolute;
        left: 6px;
        height: 15px;
        width: 15px;
        background-color: white;
        border-radius: 50%;
      }

      &__check {
        position: absolute;
        left: 0px;
        top: 5px;
        font-size: 12px;
        z-index: 1;
        opacity: 0;
        transition:
          opacity 200ms linear,
          left 100ms linear;
      }

      &.is-selected {
        background-color: #3f3f3f;

        .upsell__survey__item__check {
          display: block;
          opacity: 1;
          left: 8px;
        }
      }
    }
  }

  &__products-list {
    &__products {
      max-height: 394px;
      overflow-y: auto;
    }

    &__sale {
      max-height: 350px;
      overflow-y: auto;
    }

    &__tabs,
    &__title {
      height: 43px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgba(152, 166, 173, 0.2);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:189";