.app-search {
  &__option {
    cursor: pointer;
    padding: 8px 10px;

    &:hover {
      background-color: $light-gray;
    }

    & + & {
      border-top: 1px solid $light-gray;
    }
  }
}

;@import "sass-embedded-legacy-load-done:164";