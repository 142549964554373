@import 'variables';

// In this file all styles overwrites default bootstrap 4 or HyperReact styles
.side-nav-item.mm-active {
  background-color: $navy-dark;
}

.breadcrumb {
  .breadcrumb-item {
    font-size: 14.4px;
    font-weight: 500;

    a {
      color: #3f3f3f;
    }
  }
}

.table {
  .odd {
    background-color: $gray-50 !important;
  }

  .even {
    background-color: white !important;
  }
}

.side-nav {
  .side-nav-item.mm-active {
    background-color: $navy-dark;
  }

  .badge {
    background-color: $secondary;
    color: $white;
    margin-left: 8px;
  }
}

.form-dark .form-control,
.form-control-dark {
  background-color: $input-bg;
}

.form-style-filter {
  label {
    color: #6f757c;
  }
}

.pagination.react-bootstrap-table-page-btns-ul {
  float: right;
}

.react-bootstrap-table-pagination {
  #pageDropDown {
    color: $gray-400 !important;
    border-color: $gray-700 !important;
    background: $white !important;
    box-shadow: none;
  }
}

.nav-tabs.nav-bordered {
  li {
    a.nav-link {
      border-bottom: 2px solid transparent;

      &:hover,
      &.active {
        border-bottom-color: $gold-light;
      }
    }
  }
}

.page-title-box {
  .page-title {
    font-weight: normal !important;
  }
}

.btn {
  font-weight: 500;
  transition: all 500ms ease;
}

.modal {
  .modal-border-bottom {
    border-bottom: 1px solid #dee2e6;
  }

  .modal-title,
  .modal-header {
    font-weight: 500;
  }

  .bg-focus-grey:focus {
    background-color: $grey;
  }
}

.tooltip-inner {
  background-color: $tooltip-bg;
}

.bs-tooltip-auto[x-placement^='top'] .arrow:before,
.bs-tooltip-top .arrow:before {
  border-top-color: $tooltip-bg;
}

.bs-tooltip-auto[x-placement^='right'] .arrow:before,
.bs-tooltip-right .arrow:before {
  border-right-color: $tooltip-bg;
}

.bs-tooltip-auto[x-placement^='left'] .arrow:before,
.bs-tooltip-left .arrow:before {
  border-left-color: $tooltip-bg;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow:before,
.bottom .arrow:before {
  border-bottom-color: $tooltip-bg;
}

.Toastify {
  &__toast {
    &--success {
      color: #fff !important;
      background: $success !important;
    }

    &--error {
      color: #fff !important;
      background: #dd3535 !important;
    }
  }

  &__close-button {
    color: #fff !important;
  }
}

.notification-list {
  &__search-toggle {
    background-color: #fff;
    border: 0;
  }
}

.left-side-menu {
  &__mobile-overlay {
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(42, 58, 76, 0.37);
    transition: opacity 0.8s ease;

    &.is-transparent {
      opacity: 0;
    }

    &.is-hidden {
      display: none;
    }
  }
}

.table-active {
  td,
  th {
    background-color: #f2f3f3 !important;
  }
}

.transition-all-500-ease {
  transition: all 500ms ease;
}

.rounded-right-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-left-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}


.border-bottom-2 {
  border-bottom: 2px solid #dee2e6 !important;
}

;@import "sass-embedded-legacy-load-done:144";