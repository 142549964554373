@mixin light-scrollbar($width, $height) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    width: $width;
    height: $height;
    border-radius: 1.5px;
    background: #888;

    &:hover {
      background: #555;
    }
  }
}

@mixin dark-scrollbar() {
  &::-webkit-scrollbar-button {
    background-color: transparent;
    height: 0;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-resizer {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: transparent;
  }

  &::-webkit-scrollbar:hover {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    height: 6px;
    border: 2px solid transparent;
    border-radius: 7px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    background-clip: padding-box;
    height: 6px;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: #0d1329;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0d1329;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
    border: none;
  }
}

;@import "sass-embedded-legacy-load-done:145";