.szh-menu {
  background: #403f3f;
  border-radius: 3px;
  z-index: 1051;

  &__item {
    padding: 8px 10px;
    color: white;
    display: block;
    font-weight: 500;

    &:nth-child(odd) {
      border-bottom: 1px solid #ffffff30;
      border-top: 1px solid #ffffff30;
    }

    &--hover {
      background: #333333;
    }

    &--disabled {
      background: #403f3f40;
      opacity: 0.4;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

;@import "sass-embedded-legacy-load-done:160";