.navigation-tabs {
  display: flex;

  &__button {
    width: 100%;
    font-size: 14.4px;
    margin: 0 2px;
  }

  &__first {
    margin-left: 0;
  }

  &__last {
    margin-right: 0;
  }
}

;@import "sass-embedded-legacy-load-done:156";