.async-select {
  &__option {
    cursor: pointer;
    padding: 8px;

    & + & {
      border-top: 1px solid #dee2e6;
    }

    &.is-selected {
      background-color: #dee2e6;
    }

    &:hover {
      background-color: #dee2e6;
    }
  }
}

;@import "sass-embedded-legacy-load-done:162";