.product-select {
  &__list {
    @include light-scrollbar(5px, 5px);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 350px;
  }

  &__option {
    &__image {
      max-width: 100px;
    }
  }
}

.shop-purchase {
  &__amount {
    padding-left: 8px;
    padding-right: 25px;
    text-align: right;

    @media screen and (max-width: 767px) {
      padding-right: 8px;
    }

    &::-webkit-inner-spin-button {
      margin-right: -15px;
      height: 32px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__band-reader {
    &__internal-icon {
      font-size: 36px;
      position: absolute;
      top: 30px;
      left: 56px;
    }

    &__external-icon {
      font-size: 100px;
      margin-left: 20px;
    }
  }

  &__summary {
    &__change {
      &__container {
        border: 1px solid #dee2e6 !important;
      }
    }

    &__add-ticket {
      &__input {
        width: 120px;
      }
    }

    &__buttons {
      gap: 10px;
    }
  }

  &__payment {
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

;@import "sass-embedded-legacy-load-done:180";