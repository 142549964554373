@import 'src/assets/scss/config/saas/variables-dark';

$body-font-color: #6c757d;

$navy-light: #0f2453;
$navy-dark: #091a40;
$green: #2c8f41;
$grey: #f3f3f3;
$green-dark: #588d49;
$yellow-dark: #f6d73e;
$danger-dark: #dd3535;
$danger-light: #fa5c7c;
$white: #fff;
$white-smoke: #f5f5f5;
$white-smoke-dark: #e6e7e8;
$gold-dark: #8b764d;
$gold-light: #b99153;
$tooltip-bg: #333;
$dark-gray: #3a3a3a;
$platinum: #e6e7e8;
$purple: #727cf5;

$reservation-paid-color: #b8b8b8;
$reservation-paid-color-darker: #9b9b9b;
$reservation-confirmed-before-ap-fee-color: #03ae7e;
$reservation-confirmed-before-ap-fee-color-darker: #007d5a;
$reservation-confirmed-color: #0acf97;
$reservation-confirmed-color-darker: #0aa074;
$reservation-initial-before-ap-fee-color: #1b83a1;
$reservation-initial-before-ap-fee-color-darker: #156a82;
$reservation-initial-color: #39afd1;
$reservation-initial-color-darker: #287d96;
$reservation-checked-color: #fa5c7c;
$reservation-checked-color-darker: #c84a63;
$reservation-unfinished-color: $navy-light;
$reservation-unfinished-color-darker: $navy-dark;
$reservation-animals-color: #ffa000;
$reservation-extended-color: #cf3352;
$reservation-employee-color: #3f3f3f;

$status-green: #0acf97;
$status-blue: #39afd1;
$status-grey: #e6e7e8;
$status-black: #576067;
$status-pink: #fa5c7c;

;@import "sass-embedded-legacy-load-done:142";