@import 'variables';

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.housekeeping {
  &__notes-tooltip {
    .tooltip-inner {
      width: 165px;
      padding-right: 0;
      padding-left: 0;
      text-align: left;
    }

    &__info {
      padding: 4px 10px 0;
      font-weight: bold;
    }

    &__note {
      &__info {
        padding: 8px 10px;

        &__row {
          margin-bottom: 3px;
        }
      }

      &__content {
        padding: 8px 10px;
        border-top: 1px solid rgba(#fff, 0.4);
      }
    }
  }
  &__apartment_status {
    &__dialog {
      &__row {
        margin: 0 !important;

        &:hover {
          background-color: #f4f5f9;
        }
      }
    }
  }

  &__orders_table {
    &__column {
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      transition: all 150ms ease;
      vertical-align: middle !important;

      &__first {
        border-left: 2px solid transparent;
      }
    }
  }

  &__ordered-order {
    padding: 20px;
    background-color: $white-smoke;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--hovered {
      border: 1px dashed #a6a6a6;
      position: relative;
    }
  }
}

@keyframes infinite-spinning {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

;@import "sass-embedded-legacy-load-done:171";