$light-gray: #eff3f7;

.band {
  &__configuration {
    &__time {
      background-color: $light-gray;
      border: 1px solid $gray-700;
      padding: 10px;
      width: 60px;
      height: 60px;
      border-radius: 4px;
      text-align: center;
      font-size: 30px;
      color: #6c757d;

      &:focus-visible {
        outline: none;
      }
    }

    &__hr {
      border-color: rgba(87, 96, 103, 0.2);
      margin-block: 6px;
      margin-inline: -12px;
    }
  }

  &__scanning-guest {
    background-color: $light-gray;
    margin-bottom: 5px;
    position: relative;
    overflow-x: hidden;
    cursor: default;

    &:hover {
      .band__scanning-guest__rescan {
        opacity: 1;
        right: 0;
      }
    }

    &__rescan {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      right: -50px;
      top: 0;
      height: 100%;
      opacity: 0;
      transition-delay: 1s;
      transition:
        opacity 200ms linear,
        right 200ms linear;
      background-color: $green;
    }

    &__action {
      width: 25px;
    }

    &.is-waiting {
      border-color: $secondary !important;
    }

    &.is-scanning {
      background-color: $secondary;
      border-color: $secondary !important;
    }

    &.is-success {
      background-color: rgba(44, 143, 65, 0.2);
      border-color: $green !important;

      .band__scanning-guest__name {
        color: $green;
      }
    }

    &.is-error {
      background-color: rgba(221, 53, 53, 0.2);
      border-color: $red !important;

      .band__scanning-guest__name {
        color: $danger-dark;
      }
    }

    &__submit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 1px 1px #0000001a;
      border: none;
      background-color: $info;
      width: 320px;
      transition: opacity 0.3s ease-in-out;
      position: relative;

      &:hover {
        opacity: 0.9;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0%;
        height: 100%;
      }

      &.is-progress {
        &:after {
          animation: submit-progress-animation 4s linear forwards;
          background-color: #0d313b33;
        }
      }
    }
  }

  &__reader {
    &__wrapper {
      height: 200px;
      position: absolute;
      width: 150px;
      transition: top 0.3s ease-in-out;
    }

    &__icon {
      #status {
        &.is-scanning {
          animation:
            scanning-start-status-animation 800ms 3 forwards,
            scanning-status-animation 400ms infinite 2500ms;
        }

        &.is-success {
          fill: $green;
        }

        &.is-error {
          fill: $danger-dark;
        }
      }

      #band {
        &.is-scanning {
          fill: #606060;
          animation: scanning-band-animation 1000ms forwards;
        }

        &.is-success {
          fill: $green;
        }

        &.is-error {
          fill: $danger-dark;
        }
      }

      #icon-ok {
        transition: opacity 500ms linear;
        opacity: 0;

        &.is-success {
          opacity: 1;
        }
      }

      #icon-error {
        transition: opacity 500ms linear;
        opacity: 0;

        &.is-error {
          opacity: 1;
        }
      }

      #icon-hpr {
        opacity: 0;
        transition: opacity 500ms linear;

        &.is-scanning {
          animation: scanning-band-animation 1000ms forwards;
        }
      }

      #core {
        &.is-scanning {
          fill: #75e4c4;
          animation: scanning-circle-pulse-core 1000ms ease-in-out infinite;
        }
      }

      #radar {
        &.is-scanning {
          fill: #e0f9f2;
          animation: scanning-circle-pulse-radar 1000ms ease-in-out infinite;
        }
      }
    }
  }
}

.pulse {
  animation: submit-pulse-animation 1s infinite;
}

@keyframes submit-pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #39afd1ff;
  }

  100% {
    box-shadow: 0 0 0 5px rgba(57, 175, 209, 0.01);
  }
}

@keyframes submit-progress-animation {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes scanning-start-status-animation {
  0% {
    fill: #c1c1c1;
  }

  50% {
    fill: green;
  }

  100% {
    fill: #c1c1c1;
  }
}

@keyframes scanning-status-animation {
  0% {
    fill: #c1c1c1;
  }

  50% {
    fill: yellow;
  }

  100% {
    fill: #c1c1c1;
  }
}

@keyframes scanning-band-animation {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scanning-circle-pulse-core {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  30% {
    opacity: 1;
    transform: scale(1.5);
  }

  60% {
    opacity: 1;
    transform: scale(2.5);
  }

  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@keyframes scanning-circle-pulse-radar {
  0% {
    transform: scale(1, 1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(5, 5);
    opacity: 0;
  }
}

;@import "sass-embedded-legacy-load-done:166";