@import 'variables';

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.reception {
  &__check-reminder {
    transition: all 500ms ease;

    &__items {
      display: flex;
      align-items: center;

      &__icon {
        width: 25px;
        text-align: center;
        font-size: 16px;
      }

      &__close {
        cursor: pointer;
        margin-left: auto;

        &__button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          cursor: pointer;
          font-size: 10px !important;
        }
      }
    }

    &--is-complete {
      opacity: 0.3;

      .reception__check-reminder__items__close {
        display: none;
      }
    }

    &--has-error {
      .reception__check-reminder__items__icon {
        animation: blink 2s infinite;
        font-size: 19px;
        color: $danger-dark;
      }
    }
  }

  &__notes-tooltip {
    .tooltip-inner {
      width: 165px;
      padding-right: 0;
      padding-left: 0;
      text-align: left;
    }

    &__info {
      padding: 4px 10px 0;
      font-weight: bold;
    }

    &__note {
      &__info {
        padding: 8px 10px;

        &__row {
          margin-bottom: 3px;
        }
      }

      &__content {
        padding: 8px 10px;
        border-top: 1px solid rgba(#fff, 0.4);
      }
    }

    &__details {
      font-size: 10px;

      &__all {
        padding: 5px 0 0 0;
        border-top: 1px solid rgba(#fff, 0.4);
        text-align: center;
      }
    }
  }

  &__notification_sms_dialog {
    &__content {
      border-radius: 0;
      border: 0;
      background-color: transparent !important;
      min-height: 150px;
      outline: none !important;
      width: 100%;
      color: $body-font-color;
      padding: 0;
    }
  }

  &__check-types {
    display: flex;

    &__button {
      width: 100%;
      font-size: 14.4px;
      margin: 0 2px;
    }

    &__first {
      margin-left: 0;
    }

    &__last {
      margin-right: 0;
    }
  }

  &__bookings_table {
    &__column {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
      transition: all 150ms ease;

      &__first {
        border-left: 2px solid transparent;
      }
    }

    &__row {
      &--is-initial {
        .reception__bookings_table__column__first {
          border-left-color: $reservation-initial-color !important;
        }
      }

      &--is-check-out-started,
      &--is-check-in-started {
        .reception__bookings_table__column {
          opacity: 0.5;
        }
      }

      &--is-extended-stay,
      &--has-problem {
        .reception__bookings_table__column__first {
          border-left-color: $danger-dark !important;
        }
      }

      &__clock-button,
      &__checkout-button,
      &__checkin-button {
        line-height: 1;
        height: 37px;
        font-weight: 500;
      }

      &__apartment {
        align-items: center;
      }

      .badge {
        font-size: 7px !important;
        border-radius: 6.5px;
        padding-right: 4px;
        height: 12px;

        &.badge-success {
          background-color: $green-dark;
        }

        &.badge-warning {
          background-color: $yellow-dark;
          color: $white;
        }

        &.badge-danger {
          background-color: $danger-dark;
          color: $white;
        }

        &.badge-pink {
          background-color: $danger-dark;
          color: $white;
        }
      }
    }
  }

  &__band-reader {
    &__internal-icon {
      font-size: 36px;
      position: absolute;
      top: 25px;
      left: 32px;
    }

    &__external-icon {
      font-size: 90px;
    }
  }

  &__tablet {
    &__icon {
      font-size: 130px;
    }
  }

  &__meters-reading {
    &__row {
      &__values {
        white-space: nowrap;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }

      &__summary {
        text-align: right;
        width: 150px;
      }
    }

    &--synchronize.uil-sync::before {
      -webkit-animation: infinite-spinning 1s linear 0s infinite;
    }
  }

  &__payment-box-buttons {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
  }
}

.reception {
  &__booking-check-out__step-settlement {
    .border-gray {
      border: 1px solid #c6c8ca !important;
    }
  }
}

@keyframes infinite-spinning {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

;@import "sass-embedded-legacy-load-done:172";