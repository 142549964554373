.reports {
  &__total {
    &__row {
      border: 2px solid #5a6066;
    }
    &__white-border-left {
      border-left: 1px solid #fff;
    }
    &__column-grey {
      color: #fff;
      background: #5a6066;
      &__header {
        border-bottom: 1px solid #fff;
      }
    }
    &__column-white {
      color: #5a6066;
      &__header {
        border-bottom: 1px solid #5a6066;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:181";