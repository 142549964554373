//
// chartjs.scss
//

.chartjs-chart {
  margin: auto;
  position: relative;
  width: 100%;
}

;@import "sass-embedded-legacy-load-done:135";