.payment-cashbox {
  &__day-last-line {
    border-bottom: solid 1px #9399a0;
  }

  &__day-rows {
    border-top: solid 1px #9399a0;

    &__details_row {
      border-bottom: 1px solid #eff2f7;

      &--is-open {
        border: 2px solid $gold-light !important;
      }
    }

    &__month {
      border-right: 1px solid #edeeee;
    }
  }
}

;@import "sass-embedded-legacy-load-done:187";