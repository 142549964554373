.feeding {
  &__bestseller {
    padding-block: 2px;
    padding-inline: 4px;
    margin-left: 5px;
    font-size: 10px;
    border: 1px solid #9494944d;
    border-radius: 3px;
    background-color: $light-gray;
  }

  &__voucher {
    padding-block: 2px;
    padding-left: 4px;
    padding-right: 3px;
    margin-left: 5px;
    height: 18px;
    border-radius: 3px;
    background-color: $warning;
    font-size: 11px;
    color: black;
  }

  &__details-tooltip {
    background-color: #333333;
    width: 300px;
    max-width: 300px;
    max-height: 450px;
    overflow: auto;
    min-height: 300px;

    .spinner-border {
      color: white !important;
    }
  }

  &__unclosed-cart-banner {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ecececeb;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 15px;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
  }

  &__voucher-payment-box {
    margin-top: -20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 36px;
    border: 1px solid #fce6a6;
    background: rgba(255, 188, 0, 0.07);

    .progress {
      height: 5px;
    }
  }

  &__checkbox-label {
    position: relative;
    margin-left: -3px;
    padding-left: 3px;

    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 4px);
      height: calc(100% + 6px);
      left: 0;
      top: -3px;
      background-color: rgba(229, 229, 229, 0.3);
      border-radius: 4px;
      opacity: 0;
      transition: opacity 100ms linear;
    }

    &.is-hovered {
      &:before {
        opacity: 1;
      }
    }
  }

  &__automatic-discount-label {
    background: grey;
    border-radius: 4px;
    padding: 2px 10px;
    border: 1px solid #757575;
    margin-top: 10px;
  }
}

.tooltip.show:has(.feeding__details-tooltip) {
  opacity: 1;
}

;@import "sass-embedded-legacy-load-done:188";