.time-counter {
  &__wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    padding-top: 4px;
  }

  &__action {
    margin-top: 3px;
  }

  &__statistics {
    z-index: 11;
    position: fixed;
    bottom: 50px;
    right: 40px;
  }
}

;@import "sass-embedded-legacy-load-done:163";