.reservation-list {
  &__summary-cell {
    border-top: 3px solid #576067 !important;
    padding: 0 !important;
  }

  &__summary-block {
    background-color: #576067;
  }
}

;@import "sass-embedded-legacy-load-done:178";