.input-with-loader {
  text-overflow: ellipsis;

  &__container {
    position: relative;
    overflow: hidden;
  }

  &__spinner {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: -25px;
    width: 1.2rem;
    height: 1.2rem;
    border: 0.2em solid;
    border-right: 0.2em solid transparent;
  }

  &--active {
    .input-with-loader {
      transition: padding-right 100ms linear;
      padding-right: 40px;
    }

    .input-with-loader__spinner {
      transition: right 100ms linear;
      right: 10px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:154";