.content-loader {
  filter: none;
  transition: filter 0.2s linear;

  &--opacity {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;

    &.content-loader--loading {
      opacity: 0;
    }
  }

  &--loading {
    -webkit-filter: blur(5px);
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.1s linear;
    pointer-events: none;

    &--visible {
      opacity: 1;
    }

    &.is-centered {
      top: 40vh;
    }
  }
}

;@import "sass-embedded-legacy-load-done:155";