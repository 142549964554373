@mixin styllessButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin triangle {
  @include styllessButton;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.spin-input {
  &__wrapper {
    display: inline-block;
    position: relative;

    .form-control {
      width: 90px;
      padding-right: 30px;
    }
  }

  &__actions {
    background-color: $gray-900;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__increment {
      border-top-right-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
    }

    &__decrement {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &__increment,
    &__decrement {
      padding: 5px 3px;
      display: flex;

      &:hover {
        background-color: $gray-800;
      }

      &:active {
        background-color: $gray-500;
      }
    }
  }

  &__increment {
    @include triangle;
    border-bottom: 4px solid $gray-300;
  }

  &__decrement {
    @include triangle;
    border-top: 4px solid $gray-300;
  }
}

;@import "sass-embedded-legacy-load-done:153";