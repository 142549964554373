.reception-booking-cart {
  &__loader {
    position: relative;
    filter: none;
    transition: filter 0.2s linear;

    &.is-loading {
      opacity: 0.5;
      pointer-events: none;
    }

    &__info {
      position: absolute;
      left: 50%;
      top: 47%;
      transform: translate(-50%, -50%);
      border-radius: 4px;
      color: white;
      padding: 20px 50px 30px 50px;
      opacity: 0;
      transition:
        opacity 100ms linear,
        top 100ms linear;

      &.is-loading {
        opacity: 1;
        top: 50%;
      }
    }
  }

  &__expandable {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 19px;
      top: 50px;
      height: calc(100% - 65px);
      width: 1px;
      background-image: linear-gradient(#adb5bd 33%, #f5f5f5 0%);
      background-position: right;
      background-size: 1px 3px;
      background-repeat: repeat-y;
      z-index: 0;
    }

    &.is-nested {
      &:after {
        left: 55px;
      }
    }

    &.is-payment {
      &:after {
        left: 43px;
      }
    }

    &__item {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background: #f5f5f5;
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #cbcbcb;
        z-index: 3;
      }

      &.is-nested {
        &:before {
          left: 39px;
        }

        &:after {
          left: 42px;
        }
      }

      &__paid-icon {
        background-color: rgba(10, 207, 151, 0.18);
        border-color: #a2ebb1;
        border-radius: 4px;
        width: 20px;
        height: 15px;
        margin-right: 10px;
        position: relative;

        .uil-check {
          font-size: 14px;
          z-index: 1;
          position: absolute;
          top: 0;
          left: 4px;
        }

        &:after {
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 9px;
          height: 9px;
          border-radius: 50%;
          position: absolute;
          background-color: #0acf97;
        }
      }
    }
  }

  &__selectable {
    position: relative;
    min-height: 32px;

    &.is-selected {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background: $primary;
      }
    }
  }

  &__gastro-progress {
    height: 6px;
    border-radius: 10px;
  }

  &__payments {
    padding-left: 25px;

    &__card {
      .reception__pin-pad-content {
        display: flex;
        flex-direction: column-reverse;
      }

      .reception__payment-box-buttons {
        margin-block: 20px;

        &--submit {
          order: 2;
        }

        &--repeat {
          order: 1;
        }

        &--cancel {
          order: 3;
        }

        .btn {
          width: 100%;
          text-align: start;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:173";