.modal-details {
  background-color: #fafbfe;

  position: relative;

  & > *:not(.subscriptions-details__tabs-wrapper) {
    scroll-margin-top: 75px;
  }

  &__tabs-wrapper {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #f8fafd;
    padding-top: 15px;
  }
}

;@import "sass-embedded-legacy-load-done:185";