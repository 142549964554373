//
// progress.scss
//

// Custom height

// Progress height small
.progress-sm {
  height: 5px;
}

// Progress height medium
.progress-md {
  height: 8px;
}

// Progress height large
.progress-lg {
  height: 12px;
}

// Progress height extra large
.progress-xl {
  height: 15px;
}

;@import "sass-embedded-legacy-load-done:118";