.custom-list {
  li {
    padding: 5px;
    border-radius: 4px;

    &:hover {
      background: rgba(237, 237, 237, 0.25);
    }
  }
}

;@import "sass-embedded-legacy-load-done:165";