@mixin messageBox {
  position: relative;
  background: $light-gray;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;

  & + & {
    margin-top: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: $light-gray;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    z-index: 1;
  }
}

.reservation-details {
  .card {
    scroll-margin-top: 135px;
  }

  &__promocode-row + &__promocode-row {
    margin-top: 0.75rem;
  }

  .reservation-guests__table__column__action {
    font-size: 16px !important;
  }

  .reservation-guests {
    &__table {
      &__column {
        font-size: 0.8rem !important;
      }
    }
  }

  &__sticky-tabs-wrapper {
    position: sticky;
    top: 70px;
    z-index: 2;
    background-color: #f8fafd;
    padding-top: 15px;
  }

  &__label {
    width: 6px;
    height: 40px;
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: white;
    line-height: 1;

    &__wrapper {
      display: flex;
      gap: 2px;
    }

    &.is-active {
      flex: 1;
      padding-left: 15px;
      display: flex;
    }

    &__extended {
      justify-self: flex-end;
      height: 100%;
      width: 40px;
      background-color: #0c5e761f;
      clip-path: polygon(55% 0, 100% 0, 100% 100%, 0% 100%);
    }

    &__vip {
      justify-self: flex-end;
      height: 100%;
      background-color: black;
      padding-inline: 15px;
      display: flex;
      align-items: center;
    }

    &.is-initial {
      background-color: #39afd1;
    }

    &.is-confirmed {
      background-color: #0acf97;
    }

    &.is-checked-in {
      background-color: #fa5c7c;
    }

    &.is-finished {
      background-color: #b8b8b8;
    }
  }

  &__summary-page-link {
    color: #9099a1;
    background-color: rgba(215, 239, 246, 0.8);
    border: 1px solid #c8e9f2;
    border-radius: 4px;
    width: calc(100% - 100px);

    &__copy-button {
      width: 90%;
    }

    &__url {
      font-size: 11px;
    }
  }

  &__notifications-box {
    display: flex;
    @include messageBox;
  }

  &__notes-box {
    @include messageBox;

    &__actions {
      position: absolute;
      right: -100px;
      transition: right 0.3s linear;
      border-radius: 4px;
      bottom: 15px;
      display: flex;
      background-color: #5e676f;

      &__item {
        cursor: pointer;
        padding: 6px;
        background-color: #5e676f;
        color: white;
        position: relative;

        & + & {
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 4px;
            bottom: 4px;
            width: 1px;
            height: calc(100% - 8px);
            background: #979797;
          }
        }

        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        &:hover {
          background: darken(#5e676f, 10%);
        }
      }

      &.is-visible {
        right: 3px;
      }
    }
  }

  &__history {
    &__row {
      position: relative;

      &__content {
        &:after {
          content: '';
          position: absolute;
          top: 20px;
          left: 7px;
          width: 1px;
          height: calc(100% - 15px);
          background: #c4c4c4;
        }
      }

      & + & {
        margin-top: 10px;
      }

      &:last-of-type {
        .reservation-details__history__row__content {
          &:after {
            display: none;
          }
        }
      }
    }

    &__changes {
      &__box {
        &:after {
          pointer-events: none;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: calc(50% - 5px);
          border: 1px solid red;
          border-radius: 4px;
        }

        &:before {
          pointer-events: none;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 50%;
          border: 1px solid $green;
          border-radius: 4px;
        }

        &__value {
          &.is-first {
            max-width: calc(100% - 30px);
          }
        }

        &__table {
          width: 100%;
          table-layout: fixed;

          td {
            width: 50%;
          }
        }

        &__new {
          border-top-right-radius: 4px;
          border-bottom-left-radius: 4px;
          color: white;
          position: absolute;
          right: 0;
          top: 0;
          padding: 2px 5px;
          font-size: 10px;
          background: $green;
        }

        &__old {
          border-top-right-radius: 4px;
          border-bottom-left-radius: 4px;
          color: white;
          position: absolute;
          left: calc(50% - 35px);
          top: 0;
          padding: 2px 5px;
          font-size: 10px;
          background: $danger;
        }

        &__message {
          border-bottom-left-radius: 4px;
          color: white;
          position: absolute;
          right: 0;
          top: 0;
          padding: 2px 5px;
          font-size: 10px;
          background: $info;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:177";