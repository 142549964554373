@mixin row($background, $border) {
  background-color: $background;

  td {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
    vertical-align: middle;
  }

  td:first-of-type {
    border-left: 1px solid $border;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  td:last-of-type {
    border-right: 1px solid $border;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: right;
    width: 180px;
  }
}

.client-rodo {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    min-height: calc(100vh - 400px);
  }

  &__confirmed__table,
  &__to-verify__table {
    border-spacing: 0 5px;
    border-collapse: separate;
  }

  &__confirmed__table {
    &__row {
      @include row(rgba(44, 143, 65, 0.1), #2c8f41ff);
    }
  }

  &__to-verify__table {
    &__row {
      @include row(rgba(255, 255, 255, 0.7), #adb5bd);
    }
  }
}

;@import "sass-embedded-legacy-load-done:190";