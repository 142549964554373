.unauthorized {
  min-height: 100vh;

  @for $i from 1 through 15 {
    &__bg_#{$i} {
      background: url(../images/backgrounds/hp-auth_#{$i}.webp) center/cover no-repeat;
    }
  }

  &__header {
    font-weight: 700;
    font-size: 20px;
  }

  &__nav {
    max-width: 480px;
    padding: 48px 56px;
    background-color: #ffffff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 991px) {
      max-width: 100%;
      padding: 48px 20px;
    }
  }

  &__logo {
    margin: 0 auto 0 0;
    position: absolute;
    top: 48px;
  }
}

;@import "sass-embedded-legacy-load-done:167";