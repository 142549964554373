@import 'variables';

.reception {
  &__booking-check-in {
    width: 800px;
    max-width: 800px;

    .border {
      border-color: #cfd0d0 !important;
    }

    &__payments_step {
      &__preauthorization-box {
        &__amount_group {
          width: 100px;
        }

        &__amount {
          width: 70px;
          text-align: right;
        }

        &__last_card_numbers,
        &__code_preauth {
          max-width: 70px;
          letter-spacing: 2px;
          text-align: right;
        }

        &__code_preauth {
          max-width: 90px;
        }
      }
    }

    &__access-step {
      &__info {
        &__block {
          justify-content: space-between;
        }

        &__text {
          color: #1e5b6d;
          width: 280px;
        }

        &__icon {
          text-align: center;
          width: 66px;
          height: 66px;
          background-color: #d7eff6;
          border: 1px solid #c8e9f2;
          color: #1e5b6d;
          font-size: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 33px;
          box-shadow: 0 0 10px #f1f6f8;
        }
      }

      &__form {
        text-align: center;

        .form__input__number {
          font-weight: 500;
        }

        &__label {
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 15px;
        }
      }

      &__key-icon {
        font-size: 130px;
        font-weight: lighter;
        text-align: center;

        &--small {
          font-size: 170px;
        }
      }

      &__table-column {
        vertical-align: middle !important;
      }
    }

    &__improvement-step {
      &__table {
        border-collapse: separate;
        border-spacing: 0 5px;

        &__row {
          td:first-of-type {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
          }

          td:last-of-type {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
          }

          &--inactive {
            td {
              border-bottom: 1px solid #e4e4e4;
              border-top: 1px solid #e4e4e4;
            }

            td:first-of-type {
              border-left: 1px solid #e4e4e4;
            }

            td:last-of-type {
              border-right: 1px solid #e4e4e4;
            }
          }
        }

        td {
          vertical-align: middle;
          padding: 0.75rem;
        }

        &__data {
          vertical-align: middle !important;
        }
      }

      &__improvement-col {
        width: 230px;
      }

      &__price-col {
        width: 60px;
      }

      &__amount-col {
        width: 100px;
      }

      &--inactive-col > * {
        pointer-events: none;
        opacity: 0.2;
      }

      &__price-button {
        min-width: 160px;
      }

      &__date-range {
        &__picker {
          width: auto;
          min-width: 130px !important;

          .form-control,
          .date-range__date {
            transition: none;
            line-height: 20px;
          }
        }

        &__icon {
          background-color: white !important;
        }
      }

      &__spinner {
        width: 15px;
        height: 15px;
        border-width: 2px;

        &--white {
          color: white !important;
        }
      }

      &__vip {
        height: 110px;
        border-radius: 4px;
        box-shadow: 1px 4px 5px rgba(185, 145, 83, 0.5);

        &__name {
          width: 185px;
        }

        &--auto-height {
          height: auto;
        }

        &__wrapper {
          border-radius: 4px;
        }

        &__bg {
          position: absolute;
          right: 55px;
          top: 0;
          overflow: hidden;

          .icon-vipBg::before {
            font-size: 110px;
          }
        }

        &__icon {
          &:before {
            color: white !important;
          }

          &--gold {
            &:before {
              color: #b99153 !important;
            }
          }
        }

        &__last-col {
          text-align: right;
          position: relative;
          width: 300px;

          .btn {
            position: relative;
            z-index: 1;
          }
        }

        &__divider {
          position: relative;
          padding: 0 !important;

          &:before {
            content: '';
            width: 100%;
            height: 1px;
            background-color: #ffffff40;
            position: absolute;
            top: 2px;
            left: 0;
          }

          &--light {
            &:before {
              background-color: #dee2e6;
            }
          }
        }

        .uil-angle-down::before {
          transition: transform 0.2s linear;
        }

        &__prices {
          &__description {
            max-width: 150px;
          }

          &__amount {
            width: 100px;
          }
        }

        &__details {
          position: relative;
          column-gap: 15px;
          column-count: 3;
          padding-bottom: 15px;

          &__single {
            border-bottom: 1px solid #dee2e6;
            min-height: 45px;
            display: inline-flex;
            width: 100%;
            align-items: center;

            &--dark-border {
              border-color: #ffffff50;
            }
          }

          &--visible {
            &::before {
              transition: transform 0.2s linear;
              transform: rotate(180deg);
            }
          }
        }

        &__vip-code {
          &__status {
            position: absolute;
            right: 20px;
            bottom: 23px;
            z-index: 1;
          }

          &__loader.spinner-border {
            height: 1rem;
            width: 1rem;
            border: 2px solid;
            border-right: 0.25em solid transparent;
          }
        }
      }

      &__hpf {
        &__ticket-print-banner {
          background-size: 100% !important;
          width: 100%;
          background: url('../images/hpf-ticket-background.webp') no-repeat 0 -50px;
        }

        &__wrapper {
          color: white;
          background-size: 100% !important;
          width: 100%;
          background: url('../images/hpf-ticket-background.webp') no-repeat 0 -25px;

          &.is-added {
            background: none;
            color: $body-font-color;
          }
        }

        &__description {
          margin-left: 27px;
        }
      }

      &__subscription-voucher {
        &__information {
          margin-left: 80px;
          display: grid;
          grid-template-columns: 150px 100px;
        }

        &__watermark {
          position: absolute;
          right: 68px;
          top: -24px;
          font-size: 179px;
          color: #ffffff29;
          z-index: 0;
        }

        &__add-button {
          position: relative;
          z-index: 1;
        }
      }
    }

    &__feeding {
      &__list {
        opacity: 1;
        transition: opacity 0.1s linear;

        &--disabled {
          opacity: 0.4;
          pointer-events: none;

          .custom-control-label {
            &:before,
            &:after {
              pointer-events: none;
            }
          }
        }
      }

      &__details {
        transform: scaleY(0);
        transform-origin: top;
        max-height: 0;
        opacity: 0;
        transition:
          transform 0.2s linear,
          opacity 0.2s linear,
          max-height 0.2s linear;

        &--expanded {
          transform: scaleY(1);
          opacity: 1;
          max-height: 1000px;
        }
      }

      td {
        vertical-align: middle;
      }

      &__checkbox {
        .custom-control-label:after,
        .custom-control-label:before {
          top: 50%;
          transform: translateY(-55%);
        }
      }

      &__name-col {
        width: 150px;
      }

      &__bb-col {
        width: 95px;
      }

      &__hb-col {
        width: 118px;
      }

      &__fb-col {
        width: 160px;
      }

      &__unit-price-col {
        width: 80px;
      }

      &__expand-col {
        width: 110px;
      }

      &__total-price-col {
        width: 100px;
      }

      &__guest-select {
        &__wrapper {
          position: relative;
          width: 200px;
          background-color: white;
          border-radius: 4px;
        }

        &__action {
          transition: opacity 0.2s linear;
          opacity: 0;
          visibility: hidden;

          &--visible {
            visibility: visible;
            opacity: 1;
          }
        }

        &__list {
          border: 1px solid $light-gray;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          width: 100%;
          border-top: 0;
          background-color: white;
          max-height: 0;
          transition: all 0.1s linear;
          overflow: hidden;
          opacity: 0;

          &--visible {
            z-index: 10000;
            opacity: 1;
            max-height: 1000px;
          }
        }
      }
    }

    &__guest-form {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
    }

    &__documents-step {
      &__prints {
        &__button {
          margin-bottom: 3px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 32px;
          border: 1px solid #98a6ad;
          border-radius: 3px;
          color: #696f75;
          font-size: 14px;
          padding: 0 15px;
          cursor: pointer;

          &__line-icon {
            font-size: 16px;
            color: #e1e4e6;
          }

          &__print-icon {
            margin-top: 3px;
            margin-left: auto;
            font-size: 16px;
            color: #9fa8ae;
          }

          &--main {
            height: 40px;
            font-weight: bold;
            color: #5a6066;
            background-color: #ededee;
            border-color: #192551;
            font-size: 16px;

            .reception__booking-check-in__documents-step__prints__button__print-icon {
              color: #5a6066;
            }
          }
        }
      }
    }

    &__verification_step {
      &__input {
        letter-spacing: 2px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__voucher {
        &__no-voucher-checkbox {
          display: flex;
          align-items: center;
        }

        &__added {
          display: flex;
          align-items: center;
          height: 36px;
        }

        &__code {
          max-width: 160px;

          .form-control.is-invalid {
            background-image: none;
          }
        }

        &__add-button {
          max-height: 36px;
        }

        &__code-input {
          &__action-icon {
            position: absolute;
            top: 9px;
            right: 9px;
          }
        }
      }
    }

    &__steps {
      &__wrapper {
        padding-top: 5px;
        max-width: 100%;
        display: flex;
        justify-content: space-around;
        margin: 0 auto 15px;
        overflow: hidden;
      }

      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
      padding: 0;

      &__step {
        &.is-active {
          color: $navy-light;

          .reception__booking-check-in__steps__step__name {
            font-weight: 700;
          }
        }

        text-align: center;

        &__name {
          font-size: 14.4px;
          display: block;
          font-weight: 500;
          transition: all 200ms ease-out;
        }

        &__dot-wrapper:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          height: 3px;
          left: 21px;
          background-color: #e8e8e8;
          width: 2000px;
          margin: auto;
          transition: all 200ms ease-out;
        }

        &:last-child &__dot-wrapper:before {
          background-color: #f3f3f3;
          transition: all 200ms ease-out;
        }

        &__dot-wrapper {
          width: 25px;
          height: 25px;
          background-color: #f3f3f3;
          border: 4px solid transparent;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px auto 20px;
          position: relative;
          transition: all 200ms ease-out;

          &.is-active {
            border-color: #d2d4d8;
          }

          &.is-complete {
            &:before {
              background-color: $navy-light;
            }
          }
        }

        &__dot {
          position: absolute;
          border-radius: 50%;
          width: 7px;
          height: 7px;
          background-color: $body-font-color;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;

          &.is-active {
            background-color: $navy-light;
          }

          &.is-complete {
            background-color: #0dcf97;
          }
        }

        &__description {
          font-width: 500;
          margin-top: 5px;
          display: block;
          font-size: 10px;
        }
      }
    }

    &__notes {
      font-size: 12px;
      color: $white;
      position: absolute;
      width: 215px;
      right: -215px;
      background-color: #3a3a3aab;
      border-bottom-right-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
      transition:
        opacity 10s ease,
        transform 10s ease;
      transform-origin: top;
      animation: stretching 200ms ease;

      &.is-top {
        top: 63px;
      }

      &.is-bottom {
        bottom: 100px;
      }

      &__content {
        word-break: break-all;
      }
    }
  }

  &__booking-check-in-preview,
  &__booking-check-out-preview {
    .modal-content {
      width: fit-content;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }

  &__booking-check-in-preview {
    .alert {
      margin-bottom: 0;
    }

    .reception__booking-check-in__access-step {
      width: 800px;
    }

    &__content {
      width: 1200px;
    }

    &__guest-badge {
      &__wrapper {
        position: relative;
        width: 25px;
        height: 25px;
        max-width: 25px;
        max-height: 25px;
        background-color: #d8d8d8;
        border-radius: 50%;
      }

      &__icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__signature {
      &__preview-tooltip {
        .tooltip-inner {
          max-width: 500px;
          padding: 20px;
        }
      }

      &__wrapper {
        background: $light-gray;
        border-radius: 3px;
        border: 1px solid #9099a1;
        width: 120px;
        height: 34px;
      }
    }
  }
}

@keyframes stretching {
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

;@import "sass-embedded-legacy-load-done:174";