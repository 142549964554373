.reservation-stats {
  &__container {
    @include light-scrollbar(0px, 0px);
    overflow-x: auto;
    scrollbar-width: none;

    .table {
      td {
        padding: 7px 15px;
        vertical-align: middle;
      }
    }

    .table-striped > tbody > tr:nth-child(odd) > td {
      background-color: #ffffff;
    }

    .table-striped > tbody > tr:nth-child(even) > td {
      background-color: #f0f1f1;
    }
  }

  &__header {
    &__days {
      background-color: #f2f3fa;
      min-width: 50px;
      width: 50px;
      position: relative;
      z-index: 1;
    }

    &--weekend {
      background-color: #eae8e8;
    }

    &--today {
      background-color: #6f757c;
      color: white;
      border-top: 1px dotted black;
      border-left: 1px dotted black;
      border-right: 1px dotted black;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #6f757c;
      }
    }
  }

  &__row {
    &__icon {
      &:before {
        transition: transform 0.2s linear;
      }

      &--expanded {
        &:before {
          transform: rotate(-180deg);
        }
      }
    }

    &__report {
      td {
        background-color: #f0f1f1 !important;
      }

      &--bg-light {
        td {
          background-color: #ffffff !important;
        }
      }
    }
  }

  &__col {
    &__first {
      position: sticky;
      min-width: 250px;
      left: 0;
      z-index: 2;
    }

    &__last {
      position: sticky;
      min-width: 60px;
      text-align: center;
      right: 0;
      z-index: 2;
    }

    &--today {
      background-image: linear-gradient(#b3b4b5 49%, hsla(0, 0%, 100%, 0) 0),
        linear-gradient(#b3b4b5 49%, hsla(0, 0%, 100%, 0) 0);
      background-position: left, right;
      background-size: 1px 6px;
      background-repeat: repeat-y;
    }

    &--hovered {
      filter: brightness(0.95) !important;
    }

    &__report {
      td {
        background-color: #ffffff !important;
      }

      &--bg-light {
        td {
          background-color: #f0f1f1 !important;
        }
      }
    }
  }

  &__move-top-button {
    position: sticky;
    bottom: 0;
    z-index: 10;
    padding: 10px 0;
    background-color: $white;
    display: flex;
    justify-content: center;
  }
}

.floating-scrollbar {
  &__wrapper {
    position: sticky;
    bottom: 41px;
    z-index: 3;
  }

  &__inside {
    height: 10px;
    overflow: auto;
  }

  @include light-scrollbar(5px, 6px);
}

;@import "sass-embedded-legacy-load-done:182";