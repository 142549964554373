//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  + .breadcrumb-item {
    &::before {
      font-family: 'Material Design Icons';
    }
  }
}

;@import "sass-embedded-legacy-load-done:107";