.react-autosuggest {
  &__suggestions-container {
    display: none;

    &--open {
      display: block;
      position: absolute;
      top: 32px;
      width: 100%;
      border: 1px solid #aaa;
      background-color: #fff;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 13px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;
    }
  }

  &__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;

    &--focused {
      outline: none;
    }

    &--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__container {
    position: relative;
  }

  &__suggestion {
    cursor: pointer;
    padding: 10px 20px;

    &--highlighted {
      background-color: #ddd;
    }
  }

  &__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

;@import "sass-embedded-legacy-load-done:152";