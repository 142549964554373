.date-range {
  background-color: #fff;
  position: relative;
  min-width: 200px;

  &.is-range {
    min-width: 280px;
  }

  &.is-full-width {
    width: calc(100% - 55px);
  }

  &__placeholder {
    color: #dadada;
  }

  &__clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    height: 17px;
    width: 17px;
    background-color: $navy-light;
    border-radius: 50%;

    &::after {
      color: #fff;
      content: '\00d7';
      position: relative;
      font-size: 12px;
      width: 17px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__date {
    cursor: pointer;
    display: block;
    width: 100%;
    height: calc(2.1rem + 2px);
    padding: 0.45rem 0.9rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: $body-font-color;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;

    &.is-in-promocode-prefixes {
      background-color: #f3f3f3;
    }
  }

  &__wrapper {
    position: absolute;
    z-index: 11;
    left: 0;
    top: 100%;
    box-shadow: 2px 5px 10px #c1c1c1;
  }

  &--full-width {
    .date-range {
      width: calc(100% - 42px);
    }
  }
}

;@import "sass-embedded-legacy-load-done:150";