.custom-file-uploader {
  .dropzone__wrapper {
    min-height: auto;
    padding: 0;
    padding-block: 1px;
  }
}

.custom-phone-input {
  .react-international-phone-input {
    width: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #dee2e6;
    height: 36px;
    color: #6c757d;
  }

  &.has-error {
    .react-international-phone-input,
    .react-international-phone-country-selector-button {
      border-color: $danger;
    }
  }
}

;@import "sass-embedded-legacy-load-done:159";